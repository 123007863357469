<template>
	<div class="entity-select field-input" :class="getClasses">
		<div class="form__group field">
			<v-select v-model="selected" class="form__field" :class="selected ? 'active-input' : ''" label="label" :reduce="entity => entity['value']" :filterable="false" :options="options" @search="onSearch" :loading="isLoading" :multiple="multiple" :disabled="disabled"  >
				<template slot="no-options">
					type to search for a {{entity}}
				</template>
			</v-select>
			<label v-if="typeof label !== 'undefined'" class="form__label" >{{label}}</label>
			<div v-if="hasError" class="error">{{error}}</div>
		</div>
	</div>
</template>

<script>
	import entityMap from "@/entityMap";
	import Spinner from "@/components/loaders/Spinner";
	import {extractIdFromIri} from "@/helperFunctions";
	
	// @todo pagination of list? Both on search and initial.
	export default {
		name: "EntitySelect",
		components: {Spinner},
		data(){
			return {
				options: [],
				isLoading: false,
				total: 0,
				selected: '',
			}
		},
		computed: {
			hasError(){
				return typeof this.error !== 'undefined' && this.error !== '';
			},
			getClasses(){
				return [
					this.hasError ? this.classes+' has-error' : this.classes
				]
			},
		},
		methods: {
			determineEntitiesMissingData(){
				if(this.value === undefined || this.value.length === 0)
					return [];
				
				let missing = [];
				if(Array.isArray(this.value))
				{
					missing = this.value.map(object => { return object });
					for(let i = 0; i < this.options.length; i++)
					{
						let index = missing.indexOf(this.options[i].value);
						if(index !== -1)
						{
							missing.splice(index,1);
						}
					}
				}
				else
				{
					missing = [this.value];
					for(let i = 0; i < this.options.length; i++)
					{
						if(this.options[i].value === this.value)
						{
							let index = missing.indexOf(this.options[i].value);
							if(index !== -1)
							{
								missing.splice(index,1);
							}
							break;
						}

					}
				}
				return missing;
			},
			onSearch(search){
				this.isLoading = true;
				this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload(search))
				.then(result => {
					this.options = this.parseOptions(result['hydra:member']);
					this.total = result['hydra:allItems'];
				})
				.catch(error => {
					console.log(error);
				})
				.then(() => {
					this.isLoading = false;
				},() => {
					this.isLoading = false;
				})
			},
			buildPayload(search){
				const searchProperty = this.searchProperty+'_like';
				const parameters = this.payLoadParams;
				if(search !== '')
					parameters[searchProperty] = search;
				
				return {
					entity: {
						'@type': this.entity
					},
					parameters: parameters
				}
			},
			parseOptions(data){
				let options = [];
				
				data.forEach((point) => {
					if(this.whiteList.length === 0 || this.whiteList.indexOf(point[this.valueProperty]) !== -1)
					{
						options.push({
							label: point[this.displayProperty],
							value: point[this.valueProperty]
						})
					}
				});
				return options;
			},
			fetchEntity()
			{
				const missing = this.determineEntitiesMissingData();
				if(missing.length !== 0)
				{
					let missingIds = missing.map((iri) => {
						return extractIdFromIri(typeof iri !== 'string' ? iri['@id'] : iri);
					});
					this.isLoading = true;
					this.$store.dispatch('concrete/genericEntityRequest',{parameters:{id_equals:missingIds},entity: {'@type':this.entity}})
					.then(response => {
						if(typeof response['hydra:member'] !== 'undefined')
						{
							this.options = this.parseOptions(response['hydra:member']);
						}
						else
						{
							this.options = this.parseOptions([response]);
						}
					})
					.then(() => {
						this.isLoading = false;
					},() => {
						this.isLoading = false;
					});
				}
			}
		},
		mounted()
		{
			if(this.value !== null && this.value !== undefined && this.value.length !== 0)
			{
				this.selected = this.value;
			}
			else
			{
				this.selected = (this.multiple) ? [] : '';
			}

			// Fill out the known entities from the state, as the initial options data
			// This makes it possible for cases where the value isn't human readable (ie. an IRI like /api/countries/1)
			// to instead display the display value. Ie. "Denmark", before any ajax request has been sent.
			// @todo handle cases where the entity data set may be very large - pagination & ensure the selected value is present in final data set.
			const stateValues = this.$store.state.concrete[entityMap[this.entity].stateProperty];
			let options = [];
			
			Object.keys(stateValues).forEach((key,i) => {
				options.push(stateValues[key]);
			});
			
			this.options = this.parseOptions(options);
			this.fetchEntity()
		},
		watch: {
			value(to){
				this.fetchEntity();
				this.selected = to;
			},
			selected(to) {
				this.$emit('input',(to === null) ? '' : to);
			}
		},
		props: {
			value: String|Array,
			multiple: {
				type:Boolean,
				default: false
			},
			label: String,
			entity: {
				type: String,
				required: true
			},
			searchProperty: {
				type: String,
				required: true
			},
			displayProperty: {
				type: String,
				required: true
			},
			valueProperty: {
				type: String,
				required: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
			classes: {
				type: String,
				default: "col-md-6"
			},
			whiteList: {
				type: Array,
				default: function(){
					return [];
				}
			},
			payLoadParams: {
				type: Object,
				default: () => { return {}}
			},
			error: String,
		}
	};
</script>

<style lang="scss" scoped>
	 .field-input{
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		&.has-error{
			input{
				border-bottom: 1px solid $danger;
			}
		}
		input{
			display: block;
			::placeholder{
				color: $lightGray;
			}
		}
		.error{
			color: $danger;
            padding-left: 0px;
            font-size: 0.625rem;
		}
	}

    .form__group {
        position: relative;
        padding: 15px 0 0;
        margin-top: 0px;
    }

    .form__field {
        font-family: inherit;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $lighterGray;
        outline: 0;
        font-size: 0.875rem;
        padding: 7px 0;
        background: transparent;
        transition: border-color 0.2s;
		
        &::placeholder {
            color: transparent;
        }

        &:focus ~ .form__label {
            font-size: 0.875rem;
            cursor: text;
            top: 20px;
        }
    }

    .form__label {
        position: absolute;
        top: 5px;
        display: block;
        transition: 0.2s;
        font-size: 0.625rem;
        color: $lightGray;
        pointer-events: none;
    }

    .form__field:focus {
        ~ .form__label {
            position: absolute;
            top: 5px;
            display: block;
            transition: 0.2s;
            font-size: 0.625rem;
        }
        padding-bottom: 7px;
    }


    .form__field{
        &:required,&:invalid { box-shadow:none; }
	}

	.form__label {
		font-size: 0.875rem;
		cursor: text;
		top: 20px;
		z-index:1;
	}

    .form__field:disabled {
	    color: $lightGray;
	    -webkit-text-fill-color: #93a3ab;
	    -webkit-opacity: 1;
    }
	
</style>

<style lang="scss">
	.vs__search:focus {
        ~ .form__label {
            position: absolute;
            top: 5px;
            display: block;
            transition: 0.2s;
            font-size: 0.625rem;
        }
    }

  .vs__dropdown-toggle {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: flex;
		padding: 0 0 0px;
		background: none;
		border: 0px solid rgba(60,60,60,.26);
		border-radius: 4px;
		white-space: normal;
	}

	.vs__selected-options{
		padding: 0;
	}
	

	.vs__search, .vs__search:focus{
		padding: 0;
		margin: 0;
	}

	.vs__selected {
		margin: 0;
		padding: 0;
	}

	.vs__actions {
		padding: 0px 6px 0 3px;
	}

	.vs__spinner, .vs__spinner:after {
		border-radius: 50%;
		width: 4em;
		height: 4em;
	}

	.active-input ~ .form__label,
	.vs--open ~ .form__label,
	.vs--loading ~ .form__label {
		position: absolute;
		top: 5px;
		display: block;
		transition: 0.2s;
		font-size: 0.625rem;
	}

	.vs__selected {
		padding: 0 5px;
		margin: 0 5px 0 0;
	}

</style>
