import md5 from 'md5';
let waitingForApiResponses = {};
export default {
	data(){
		return {
			isFetchingRequiredApiData: false
		}
	},
	methods:{
		/**
		 * Checks for the existence of a given request in the request stack.
		 * If the request does not exist, creates it and returns a generic promise, which will be fulfilled upon request completion, to the caller.
		 * If it does exist, returns the already running request promise to the caller.
		 * Requests are identified by a md5 & json encoded string, meaning the order of object properties is relevant to identifying already running requests.
		 *
		 * @param request
		 * @returns Promise
		 */
		addRequiredApiRequest(request)
		{
			const key = md5(JSON.stringify(request));
			if(typeof waitingForApiResponses[key] === 'undefined')
			{
				waitingForApiResponses[key] = new Promise((resolve, reject) =>
				{
					this.isFetchingRequiredApiData = true;
					if (request.type === 'generic')
					{
						this.$store.dispatch('concrete/genericEntityRequest', request.payload)
						.then(response => {
							resolve(response);
						})
						.catch(error => {
							reject(error);
						});
					}
					else if(request.type === 'static')
					{
						if(Object.keys(this.$store.state.concrete[request.stateProperty]).length !== 0)
							resolve(this.$store.state.concrete[request.stateProperty])
						else
						{
							this.$store.dispatch('concrete/genericEntityRequest', request.payload)
							.then(response => {
								resolve(this.$store.state.concrete[request.stateProperty]);
							})
							.catch(error => {
								reject(error);
							});
						}
					}
					else if(request.type === 'action')
					{
						this.$store.dispatch('concrete/'+request.action, request.payload)
						.then(response => {
							resolve(response);
						})
						.catch(error => {
							reject(error);
						});
					}
					else
					{
						reject('Unknown request structure');
					}
				});
				
				waitingForApiResponses[key].then(() => {
					delete waitingForApiResponses[key];
					if (Object.keys(waitingForApiResponses).length === 0)
						this.isFetchingRequiredApiData = false;
				},() => {
					delete waitingForApiResponses[key];
					if (Object.keys(waitingForApiResponses).length === 0)
						this.isFetchingRequiredApiData = false;
				})
			}
			return waitingForApiResponses[key];
		}
	},
}
