<template>
	<div class="user-add-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">
				<template v-if="isCreateView">
					{{$t("titles.addUser")}}
				</template>
				<template v-else>
					{{$t("titles.editUser")}}
				</template>
			</div>
			<div v-if="$route.name !== 'user-edit' || mayEdit" class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$route.name === 'user-edit' ? $t('terms.update') : $t('terms.create')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="isFetchingRequiredApiData === true" />
		<template v-else>
			<form-container>
				<div class="part-label col-12">{{$t("titles.userDetails")}}</div>
				<field-input :label='$t("labels.name")' v-model="entity.name" :error="errors.name" />
				<field-input :label='$t("labels.email")' v-model="entity.email" :error="errors.email" />
				<field-input :label='$t("labels.phone")'  v-model="entity.mobile" :error="errors.mobile"  />
				<field-select :label='$t("labels.status")'  v-model="entity.state" :options="stateOptions" :error="errors.state" />
				<field-select :label='$t("labels.locale")'  v-model="entity.locale" :options="localeOptions" :error="errors.locale" />
			</form-container>
			<form-container v-if="mayViewCompany">
				<div class="part-label col-12">{{$t("titles.company")}}</div>
				<entity-select v-model="entity.companies" :label='$t("labels.memberOfCompany")' entity="Company" search-property="name" display-property="name" value-property="@id" :error="errors.districts" :disabled="!mayChangeCompany" />
				<entity-select v-if="mayChangeCompanyGroups" v-model="entity.userHasPermissionGroupOnCompany" :white-list="possibleCompanyGroups" :label='$t("labels.companyPermissionGroups")' entity="PermissionGroup" search-property="name" display-property="name" value-property="@id" :error="errors.userHasPermissionGroupOnCompany" :multiple="true" :disabled="!mayChangeCompanyGroups"/>
			</form-container>
			<form-container v-if="mayViewDistrict">
				<div class="part-label col-12">{{$t("titles.district")}}</div>
				<entity-select v-model="entity.districts" :label='$t("labels.district")' entity="District" search-property="name" display-property="name" value-property="@id" :error="errors.districts" :disabled="!mayChangeDistrict" />
				<entity-select v-if="mayChangeDistrictGroups" v-model="entity.userHasPermissionGroupOnDistrict" :white-list="possibleDistrictGroups" :label='$t("labels.districtPermissionGroups")' entity="PermissionGroup" search-property="name" display-property="name" value-property="@id" :error="errors.userHasPermissionGroupOnDistrict" :multiple="true" :disabled="!mayChangeDistrictGroups" />
			</form-container>
		</template>
	</div>
</template>

<script>
	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState,mapGetters} from "vuex";
	import isGranted from "@/mixin/isGranted";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import permissions from "@/permissions";
	import requiresApiRequest from "@/mixin/requiresApiRequest";
	import {extractIdFromIri} from "@/helperFunctions";
	import SelectInput from "@/components/inputs/SelectInput";
	import permissionGroups from "@/permissionGroups";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import SmallButton from "@/components/buttons/SmallButton";
	import FieldSelect from "@/components/inputs/FieldSelect";
	
	export default {
		name: "AddEditUser",
		components: {
			FieldSelect,
			EntitySelect, Spinner, StandardButton, RegularInput, FormContainer, FieldInput, SelectInput, SmallButton},
		mixins: [isGranted,requiresApiRequest],
		data(){
			return {
				requiredApiEntities: [],
				hasFetchedData: true,
				stateOptions: [
					{
						value: 0,
						text: this.$t('terms.inactive')
					},
					{
						value: 1,
						text: this.$t('terms.active')
					}
				],
				localeOptions: [
					{
						value: 'da',
						text: this.$t('locales.da')
					},
					{
						value: 'en',
						text: this.$t('locales.en')
					}
				],
				entity: {
					name: '',
					email: '',
					districts: '',
					companies: '',
					mobile: '',
					state: 1,
					locale: 'da',
					userHasPermissionGroupOnDistrict: [],
					userHasPermissionGroupOnCompany: [],
				},
				errors: {
					name: '',
					email: '',
					districts: '',
					companies: '',
					mobile: '',
					state: '',
					locale: '',
					userHasPermissionGroupOnDistrict: '',
					userHasPermissionGroupOnCompany: '',
				},
				responseError: '',
				loader: false
			}
		},
		computed: {
			...mapState('concrete', {
  				users: 'users',
				permissionGroups: 'permissionGroups',
				viewingEntity: 'viewingEntity',
				companies: 'companies',
				districts: 'districts',
				userPermissionGroup: 'userPermissionGroup'
			}),
			...mapGetters('concrete', {
				hasGlobalSuperAdmin: 'hasGlobalSuperAdmin',
				viewingEntityIRIParts: 'viewingEntityIRIParts'
			}),
			possibleCompanyGroups(){
				const accepted = [];
				Object.keys(this.permissionGroups).forEach((key) => {
					if(this.permissionGroups[key].code === permissionGroups.ENTREPRENEUR.key || this.permissionGroups[key].code === permissionGroups.COMPANY.key)
					{
						accepted.push(this.permissionGroups[key]['@id']);
					}
					else if(this.permissionGroups[key].code === permissionGroups.PARTNER.key && this.hasGlobalSuperAdmin)
						accepted.push(this.permissionGroups[key]['@id'])
				});

				return accepted;
			},
			possibleDistrictGroups(){
				const accepted = [];
				Object.keys(this.permissionGroups).forEach((key) => {
					if(this.permissionGroups[key].code === permissionGroups.PILOT.key || this.permissionGroups[key].code === permissionGroups.DISTRICT_MANAGER.key)
					{
						accepted.push(this.permissionGroups[key]['@id']);
					}
				});
				return accepted;
			},
			viewingEntityType(){
				return typeof this.viewingEntityIRIParts.entity !== 'undefined' ? this.viewingEntityIRIParts.entity : false ;
			},
			isCreateView(){
				return typeof this.$route.params.id === 'undefined';
			},
			routeEntity(){
				return (this.isCreateView === false && typeof this.users[this.$route.params.id] !== 'undefined') ? this.users[this.$route.params.id] : false;
			},
			routeEntityDistrictGroups(){
				return this.routeEntity !== false ? this.routeEntity.userHasPermissionGroupOnDistrict.map((value) => {return this.permissionGroups[extractIdFromIri(value['permissionGroup'])].code;}) : false;
			},
			routeEntityCompanyGroups(){
				return this.routeEntity !== false ? this.routeEntity.userHasPermissionGroupOnCompany.map((value) => {return this.permissionGroups[extractIdFromIri(value['permissionGroup'])].code;}) : false;
			},
			hasError(){
				if(this.responseError !== '')
					return true;
				let error = false;
				
				Object.keys(this.errors).forEach((key) => {
					if(this.errors[key] !== '')
						error = true;
				});
				
				return error;
			},
			mayChangeDistrictGroups(){
				if(this.entity.districts === '')
					return false;

				if(this.viewingEntityType === 'Company' && this.isGranted(permissions.USER.PERMISSION.UPDATE,this.viewingEntity))
					return true;

				if(this.isGranted(permissions.USER.PERMISSION.UPDATE,this.entity.districts))
					return true;
				
				let allowWithPermissions = [];
				
				if(this.routeEntityDistrictGroups !== false && this.routeEntityDistrictGroups.indexOf(permissionGroups.DISTRICT_MANAGER.key) !== -1)
					allowWithPermissions.push(permissions.USER.MANAGE.DISTRICT_MANAGER);
				
				if(this.routeEntityDistrictGroups !== false && this.routeEntityDistrictGroups.indexOf(permissionGroups.PILOT.key) !== -1)
					allowWithPermissions.push(permissions.USER.MANAGE.PILOT);
				
				
				if(this.isCreateView && this.viewingEntity !== false)
					allowWithPermissions.push(permissions.USER.MANAGE.PILOT);
				
				
				return (allowWithPermissions.length > 0 && this.isGranted(allowWithPermissions,this.entity.districts));
			},
			mayChangeCompanyGroups(){
				if(this.entity.companies === '')
					return false;
				
				if(this.isGranted(permissions.USER.PERMISSION.UPDATE,this.entity.companies))
					return true;
				
				let allowWithPermissions = [];
				
				if(this.routeEntityCompanyGroups !== false && this.routeEntityCompanyGroups.indexOf(permissionGroups.COMPANY.key) !== -1)
					allowWithPermissions.push(permissions.USER.MANAGE.COMPANY);
				
				if(this.routeEntityCompanyGroups !== false && this.routeEntityCompanyGroups.indexOf(permissionGroups.ENTREPRENEUR.key) !== -1)
					allowWithPermissions.push(permissions.USER.MANAGE.ENTREPRENEUR);
				
				if(this.isCreateView && this.viewingEntity !== false)
					allowWithPermissions.push(permissions.USER.MANAGE.ENTREPRENEUR);
				
				return (allowWithPermissions.length > 0 && this.isGranted(allowWithPermissions,this.entity.companies));
			},
			mayViewCompany(){
				if(this.entity.districts.length > 0)
					return false;

				if(this.viewingEntityType === 'Company')
					return true;
				else if(this.viewingEntityType !== false)
					return false;

				return this.hasGlobalSuperAdmin
			},
			mayViewDistrict(){
				if(this.entity.companies.length > 0)
					return false;

				if(this.viewingEntityType === 'District')
				{
					return true;
				}
				else if(this.viewingEntityType === 'Company')
				{
					return this.isGranted(permissions.USER.PERMISSION.UPDATE,this.viewingEntity)
				}
				else if(this.viewingEntityType !== false)
					return false;
				
				return this.hasGlobalSuperAdmin
			},
			mayChangeCompany(){
				if(this.viewingEntityType === 'Company' && this.isGranted(permissions.USER.PERMISSION.UPDATE,this.viewingEntity))
					return true;

				return this.hasGlobalSuperAdmin
			},
			mayChangeDistrict(){
				if(this.viewingEntityType === 'Company' && this.isGranted(permissions.USER.PERMISSION.UPDATE,this.viewingEntity))
					return true;

				return this.hasGlobalSuperAdmin
			},
			mayEdit(){
				if(this.hasGlobalSuperAdmin || this.isGranted(permissions.USER.UPDATE) )
					return true;
				
				return (this.viewingEntity !== false) ? this.isGranted(permissions.USER.UPDATE,this.viewingEntity) : false;
			}
		},
		methods: {
			buildPayload(){
				let payload = {
					entity: {
						'@type': 'User'
					},
					method: (this.$route.name === 'user-edit') ? 'put' : 'post',
					payload: {
						name: this.entity.name,
						email: this.entity.email,
						mobile: this.entity.mobile,
						state: Number(this.entity.state),
						locale: this.entity.locale
					}
				};
				
				if(this.$route.name === 'user-edit')
					payload.entity.id = this.$route.params.id;
				
				if(this.entity.districts !== '')
				{
					payload.payload.districts = [this.entity.districts];
					if(typeof payload.payload.dynamicPermissions === 'undefined')
						payload.payload.dynamicPermissions = {};
					
					payload.payload.dynamicPermissions[this.entity.districts] = {
						groups: this.entity.userHasPermissionGroupOnDistrict
					}
				}
				
				if(this.entity.companies !== '')
				{
					payload.payload.companies = [this.entity.companies];
					if(typeof payload.payload.dynamicPermissions === 'undefined')
						payload.payload.dynamicPermissions = {};
					
					payload.payload.dynamicPermissions[this.entity.companies] = {
						groups: this.entity.userHasPermissionGroupOnCompany
					}
				}
				
				return payload;
			},
			attemptSubmit(){
				if(this.loader) return;
				
				this.validateInputs();
				if(!this.hasError)
				{
					this.loader = true;
					this.responseError = '';
					this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
					.then(result => {
						if(typeof result.id !== 'undefined')
						{
							if(this.$route.name === 'user-add')
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.userWasCreatedTitle"),
									text: this.$t("alerts.userWasCreatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) => {
									if (result.isConfirmed) {
										this.$router.push({ name: 'entity-list', params: {entityType: 'users',lang: this.$i18n.locale} })
									}
								});
							}
							else
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.userWasUpdatedTitle"),
									text: this.$t("alerts.userWasUpdatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((response) => {
									if (response.isConfirmed) {
										this.$router.push({ name: 'user-view', params: {id: result.id,lang: this.$i18n.locale} })
									}
								});
							}
						}
						else
						{
							//Can this happen?
							console.log(result);
							this.fireGenericError();
						}
					})
					.catch(error => {
						console.log(error);
						if(typeof error.data !== 'undefined')
						{
							// @todo Handle specific errors
							if(typeof error.data['violations'] !== 'undefined')
							{
								error.data['violations'].forEach((error) => {
									if(typeof this.errors[error.propertyPath] !== 'undefined')
									{
										this.errors[error.propertyPath] = this.$t('symfonyErrors.'+error.message.replace(/\./g, ""));
									}
								});
								this.$swal.fire({
									icon: 'error',
									title: this.$t("terms.error"),
									text: this.$t("errors.errorsInFields"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								});
							}
							else
							{
								this.fireGenericError();
							}
						}
						else
						{
							this.fireGenericError();
						}
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
			},
			validateInputs(){

				this.responseError = '';
				this.errors = {
					name: '',
					email: '',
					districts: '',
					companies: '',
					mobile: '',
					state: '',
					locale: '',
					userHasPermissionGroupOnDistrict: '',
					userHasPermissionGroupOnCompany: '',
				};
				
				if(this.entity.name === '')
					this.errors.name = this.$t("validation.pleaseProvideName");
				
				if(this.entity.email === '')
					this.errors.email = this.$t("validation.pleaseProvideEmail");
				
				if(this.entity.mobile === '' && this.entity.userHasPermissionGroupOnCompany.length !== 0)
					this.errors.mobile = this.$t("validation.pleaseProvideMobile");
				
			},
			updateLocalValues(){
				Object.keys(this.entity).forEach(key => {
					if(key === 'districts' || key === 'companies')
					{
						this.entity[key] = (this.routeEntity[key].length !== 0) ? this.routeEntity[key][0] : '';
					}
					else if(key === 'userHasPermissionGroupOnDistrict' || key === 'userHasPermissionGroupOnCompany')
					{
						this.entity[key] = (this.routeEntity[key].length !== 0) ? this.routeEntity[key].map((value) => {return this.permissionGroups[extractIdFromIri(value['permissionGroup'])]['@id'];}) : [];
					}
					else
					{
						this.entity[key] = this.routeEntity[key] !== null ? this.routeEntity[key] : '';
					}
				});
			},
			applyViewingEntity(){
				if(this.viewingEntity !== false)
				{
					const permissionKeys = Object.keys(this.permissionGroups);
					if(this.viewingEntityType === 'Company')
					{
						if(this.viewingEntityType === 'Company' && this.isGranted(permissions.USER.PERMISSION.UPDATE,this.viewingEntity))
							return true;

						this.entity.companies = this.viewingEntity;
						for(let i = 0; i < permissionKeys.length; i++)
						{
							if(this.permissionGroups[permissionKeys[i]].code === permissionGroups.ENTREPRENEUR.key)
							{
								
								this.entity.userHasPermissionGroupOnCompany = [this.permissionGroups[permissionKeys[i]]['@id']];
								break;
							}
						}
					}
					else
					{
						this.entity.districts = this.viewingEntity;
						for(let i = 0; i < permissionKeys.length; i++)
						{
							if(this.permissionGroups[permissionKeys[i]].code === permissionGroups.PILOT.key)
							{
								this.entity.userHasPermissionGroupOnDistrict = [this.permissionGroups[permissionKeys[i]]['@id']];
								break;
							}
						}
					}
				}
			}
		},
		mounted()
		{
			let promises = [];
			promises.push(this.addRequiredApiRequest({type: 'static',stateProperty: 'permissionGroups',payload:{entity: {'@type':'PermissionGroup'},parameters:{prPage:'9999'}}}));
			if(typeof this.$route.params.id !== 'undefined')
				promises.push(this.addRequiredApiRequest({type: 'generic',payload:{entity: {'id': this.$route.params.id,'@type':'User'}}}))

			Promise.all(promises)
			.then(response => {
				if(typeof this.$route.params.id !== 'undefined')
					this.updateLocalValues();
				this.applyViewingEntity();
			})
			.catch(error => {
				// @todo error handling - logout / reset?
				console.log(error);
			})
		},
		watch:{
			$route(){
				if(typeof this.$route.params.id !== 'undefined')
				{
					this.addRequiredApiRequest({type: 'generic',payload:{entity: {'id': this.$route.params.id,'@type':'User'}}})
					.then(result => {
						this.updateLocalValues();
					})
					.catch(error => {
						console.log(error);
					})
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.part-label{
		font-size: 1rem;
		color: $lightGray;
		pointer-events: none;
	}
</style>
